import { Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { isMutation } from './utilities';
import { Execution } from '@/common/providers/AutoSavingProvider/AutoSavingContext';
import { getCurrentAutoSavingContext } from '@/common/providers/AutoSavingProvider/AutoSavingProvider';
import { AutoSavingError, AutoSavingStatus } from '@/types/autoSaving';

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // Log errors
    if (process.env.NODE_ENV !== 'production') {
      const context = operation.getContext();
      if (context.logErrors !== false) {
        if (graphQLErrors) {
          graphQLErrors.forEach((error) =>
            // eslint-disable-next-line no-console
            console.error(
              `[GraphQL error]: ${operation.operationName} \n ${JSON.stringify(
                error,
                null,
                4
              )}`
            )
          );
        }
        if (networkError) {
          // eslint-disable-next-line no-console
          console.error(
            `[Network error]: ${JSON.stringify(networkError, null, 4)}`
          );
        }
      }
    }

    // Update the auto saving context
    if (isMutation(operation)) {
      const operationContext = operation.getContext();
      const autoSavingContext = getCurrentAutoSavingContext();
      if (operationContext.id && autoSavingContext) {
        return new Observable((observer) => {
          const setError = (execution: Execution) => {
            if (networkError) {
              autoSavingContext.updateExecution({
                ...execution,
                error: AutoSavingError.NetworkError,
              });
            } else if (graphQLErrors) {
              autoSavingContext.updateExecution({
                ...execution,
                error: AutoSavingError.Unexpected,
              });
            }
          };

          const execution: Execution = {
            id: operationContext.id,
            status: AutoSavingStatus.Failed,
            retry: () => {
              autoSavingContext.updateExecution({
                id: operationContext.id,
                status: AutoSavingStatus.Retrying,
              });

              // Call the operation again by putting it here again into the link chain
              forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: (errorValue) => {
                  // Set the auto saving context back to the detected error
                  setError(execution);

                  observer.error.bind(observer)(errorValue);
                },
                complete: observer.complete.bind(observer),
              });
            },
          };

          setError(execution);
        });
      }
    }

    return forward(operation);
  }
);
