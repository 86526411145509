import { useCallback, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { AuthenticationContext } from '@/common/providers/AuthenticationProvider/AuthenticationContext';

let checkingSession = false;

export const useCheckSession = () => {
  const { client: auth0Client } = useContext(AuthenticationContext);
  const apolloClient = useApolloClient();

  const checkSession = useCallback(async () => {
    try {
      if (!checkingSession) {
        checkingSession = true;

        // Check and create a new session (token)
        await auth0Client?.checkSession();

        // Reset the cache and run all active queries again, because the permissions and token changed
        await apolloClient.cache.reset();
      }
    } finally {
      checkingSession = false;
    }
  }, [apolloClient.cache, auth0Client]);

  return checkSession;
};
