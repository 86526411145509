import { Scalars } from '@/api/graphql';

export type WithId = {
  id: Scalars['ID']['output'];
};

export const isWithId = (input: any): input is WithId => !!(input as WithId).id;

export type WithTypename = {
  __typename: string;
};

export const isWithTypename = (input: any): input is WithTypename =>
  // eslint-disable-next-line no-underscore-dangle
  !!(input as WithTypename).__typename;

export type WithItems = {
  items: unknown[];
  nextToken?: string;
};

export const isWithItems = (input: any): input is WithItems =>
  !!(input as WithItems).items;

export type BeginsWithId = {
  id?: {
    beginsWith?: string;
  };
};

export const isWithBeginsWithId = (input: any): input is BeginsWithId =>
  !!(input as BeginsWithId)?.id?.beginsWith;

export const isJSXElement = (input: any): input is JSX.Element =>
  !!(input as JSX.Element)?.props;
