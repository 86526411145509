import { ApolloLink, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import isPlainObject from 'lodash/isPlainObject';
import { v4 as uuid } from 'uuid';
import { isMutation } from './utilities';
import { getCurrentAutoSavingContext } from '@/common/providers/AutoSavingProvider/AutoSavingProvider';
import { delay } from '@/common/utils/delay';
import { AutoSavingStatus } from '@/types/autoSaving';

/**
 * Convert the properties of an object to a string, without their value
 * @example
 * {id: 1, someKey: 'value', anotherKey: {nested: 'value', ignore: undefined }};
 * => ["id(1)", "someKey", "anotherKey:nested"]
 */
const keys = (obj: {}): string[] =>
  Object.entries(obj).flatMap(([key, value]) => {
    // Filter out undefined values to only keep key/value pairs that actually were changed
    if (value === undefined) {
      return [];
    }
    // Check if the value is a nested object, if true run recursively
    if (isPlainObject(value)) {
      return [
        key,
        ...keys(value as {}).map((childKey) => `${key}:${childKey}`),
      ];
    }
    // The property value is added in parenthesis.
    else {
      return [key === 'id' || key.endsWith('Id') ? `${key}(${value})` : key];
    }
  });

// A dictionary of the mutations requests, that holds a unique id for the same mutations and an unique id for each execution
const requests: {
  [id: string]: string;
} = {};

const debounceLink = setContext(async (operation, prevContext) => {
  if (isMutation(operation)) {
    // Debounce timeout
    const timeout: number = (() => {
      if (typeof prevContext.delay === 'boolean') {
        return prevContext.delay ? 1000 : 0;
      }
      if (typeof prevContext.delay === 'number') {
        return prevContext.delay;
      }

      // delay is undefined - set default delay
      return 0;
    })();

    // We only have to debounce, if a delay is set
    if (timeout > 0) {
      // Get the id for the current mutation
      const id = `${operation.operationName}-${
        operation.variables ? keys(operation.variables).join(',') : uuid()
      }`;

      // Set the execution id to this one
      const executionId = uuid();
      requests[id] = executionId;

      // Set the auto saving status
      const context = getCurrentAutoSavingContext();
      context?.updateExecution({
        id,
        status: AutoSavingStatus.InProgress,
      });

      // Await the timeout. This request is debounced if another one with the same variables is added to the queue
      await delay(timeout);

      const result = {
        ...prevContext,
        id,
      };

      // Another mutation with the same input keys was executed. So we have to abort the current one
      if (requests[id] !== executionId) {
        return {
          ...result,
          debounced: true,
        };
      }

      return result;
    }
  }

  return prevContext;
});

const responseLink = new ApolloLink((operation, forward) => {
  if (isMutation(operation)) {
    const operationContext = operation.getContext();
    if (
      'debounced' in operationContext &&
      operationContext.debounced === true
    ) {
      // Abort the mutation, because it was debounced
      return new Observable((subscriber) => {
        subscriber.error(new Error('debounced'));
      });
    } else {
      // Set the auto saving status for a mutation to be succeeded
      return forward(operation).map((result) => {
        const autoSavingContext = getCurrentAutoSavingContext();
        if (operationContext.id && autoSavingContext && result.data) {
          autoSavingContext.updateExecution({
            id: operationContext.id,
            status: AutoSavingStatus.Success,
          });
        }

        return result;
      });
    }
  }

  return forward(operation);
});

export const autoSavingLink = ApolloLink.from([debounceLink, responseLink]);
