import { ApolloLink, FetchResult, Observable } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

const getNextToken = (
  data: Record<string, any> | null | undefined
): string | null | undefined => {
  if (data) {
    if ('nextToken' in data) {
      return data.nextToken;
    }

    for (const key in data) {
      const value = data[key];
      if (typeof value === 'object') {
        const childToken = getNextToken(value);
        if (childToken || childToken === null) {
          return childToken;
        }
      }
    }
  }

  return undefined;
};

export const fetchAllLink = new ApolloLink((operation, forward) => {
  // Determine whether a nextToken can be set for the operation
  const mainDefinition = getMainDefinition(operation.query);
  if (
    !mainDefinition.variableDefinitions?.some(
      (definition) => definition.variable.name.value === 'nextToken'
    )
  ) {
    // Continue without checking for a nextToken, if not defined in the operation
    return forward(operation);
  }

  // If fetchAll was set to false in the context of a query, this link will be skipped
  const context = operation.getContext();
  if ('fetchAll' in context && context.fetchAll === false) {
    return forward(operation);
  }

  return new Observable((observer) => {
    const handleResult = (
      fetchResult: FetchResult<
        Record<string, any>,
        Record<string, any>,
        Record<string, any>
      >
    ) => {
      // Continue with the next link

      const { data } = fetchResult;
      const nextToken = getNextToken(data);
      observer.next(fetchResult);

      // If there is a nextToken, fetch more data
      if (nextToken) {
        operation.variables = {
          ...operation.variables,
          nextToken,
        };

        forward(operation).subscribe(handleResult);
      } else {
        // Complete the observer if there are no more pages
        observer.complete();
      }
    };

    // Start the initial request
    forward(operation).subscribe(handleResult);
  });
});
