import { ApolloLink } from '@apollo/client';
import isObjectLike from 'lodash/isObjectLike';
import { isSubscription, isMutation } from './utilities';
import {
  NVePriorityUpdateSubscription,
  NVeTopicRelationType,
} from '@/api/graphql';
import { omitTypename } from '@/common/utils/omitTypename';
import { isWithTypename } from '@/common/utils/typeGuards';

const getTypenameForPriorityUpdateSubscription = (
  type?: NVeTopicRelationType | string | null
) => {
  switch (type) {
    case NVeTopicRelationType.Company:
      return 'NVeCompany';
    case NVeTopicRelationType.TopicArticle:
      return 'NVeTopicArticle';
    case NVeTopicRelationType.Topic:
      return 'NVeTopic';
  }
};

/**
 * Clean the typename to match the cached value of the typename property,
 * because AppSync creates different object types for subscription and mutation responses.
 *
 * @param {*} input
 */
export const replaceTypename = (input: any): any => {
  if (isObjectLike(input)) {
    if (isWithTypename(input)) {
      switch (input.__typename) {
        case 'ArticleDelete':
          return {
            ...input,
            __typename: 'ArticleMetadata',
          };
        case 'NVeTopicSavedUpdateSubscription':
          return {
            ...input,
            __typename: 'NVeTopic',
          };
        case 'NVePriorityUpdateSubscription':
          const { type, ...restPriorityUpdateSubscription } =
            input as NVePriorityUpdateSubscription;
          return {
            ...restPriorityUpdateSubscription,
            __typename: getTypenameForPriorityUpdateSubscription(type),
          };
        default:
          return {
            ...input,
            __typename: input.__typename.replace(
              /(Subscription){0,1}(Delete|Update){0,1}$/,
              ''
            ),
          };
      }
    } else {
      // Go through all values of the input
      if (Array.isArray(input)) {
        return input.map(replaceTypename);
      }
      return Object.fromEntries(
        Object.entries(input).map(([key, value]) => [
          key,
          replaceTypename(value),
        ])
      );
    }
  }

  return input;
};

export const typenameLink = new ApolloLink((operation, forward) => {
  // Removes the __typename property from the variables
  operation.variables = omitTypename(operation.variables);

  if (isSubscription(operation) || isMutation(operation)) {
    return forward(operation).map((result) => replaceTypename(result));
  }

  return forward(operation);
});
