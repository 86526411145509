import { createContext } from 'react';

export type OptimisticEvent = {
  cacheId: string;
};

export type GraphqlContextType = {
  clientToken: string;
  publishCreate: (event: OptimisticEvent, typename: string) => void;
  subscribeCreate: (
    onCreate: (event: OptimisticEvent) => void,
    typename: string
  ) => string;
  unsubscribeCreate: (subscriptionId: string) => void;
};

export const GraphqlContext = createContext<GraphqlContextType>({
  clientToken: '',
  publishCreate: () => {},
  subscribeCreate: () => '',
  unsubscribeCreate: () => {},
});
