'use client';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseErrorPage } from './BaseErrorPage';
import { LockIcon } from '@/common/icons';

export const Error403Page: FC = () => {
  const { t } = useTranslation();

  return (
    <BaseErrorPage
      title={t('global:errors:403:title')}
      description={t('global:errors:403:description')}
      icon={<LockIcon width="52px" height="52px" />}
      toHref="/"
      toText={t('global:errors:403:button')}
    />
  );
};
