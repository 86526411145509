import {
  AclGlobalRoleDefinition,
  AclLevel,
  AclAction,
  AclStatementEffect,
  AclGlobalPermissionTreeNode,
  AclRoleAssignmentData,
  AclStatement,
} from '@/api/graphql';
import { getEntityIdsWithoutVersion } from '@/common/utils/getEntityIdWithoutVersion';

export type AuthEntityRole = { entityId: string } & AclGlobalRoleDefinition;

export type AuthMemberPermissions = {
  entityId?: string;
  level?: AclLevel;
  operation?: AclAction;
  auth?: {
    [key in AclLevel]?: {
      [effect in AclStatementEffect]?: AclAction[];
    };
  };
};

export const searchPermissionTreeNode = (
  node: Array<AclGlobalPermissionTreeNode | null>,
  level: AclLevel
): AclGlobalPermissionTreeNode | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const n of node) {
    if (n) {
      if (n.level === level) {
        return n;
      }
      if (n.children) {
        const found = searchPermissionTreeNode(n.children, level);
        if (found) {
          return found;
        }
      }
    }
  }
  return undefined;
};

export const flatTree = (
  node: AclGlobalPermissionTreeNode
): Array<AclGlobalPermissionTreeNode> => {
  const flatChildren = node.children
    ? (node.children
        .map((n) => (n ? flatTree(n) : undefined))
        .filter((e) => e) as Array<AclGlobalPermissionTreeNode[]>)
    : [];
  return [node, ...flatChildren.flat()];
};

export const getRelevantUserRoles = (
  referenceId: string,
  userRoles?: AclRoleAssignmentData[],
  globalRoleDefinitions?: AclGlobalRoleDefinition[]
): AuthEntityRole[] | undefined =>
  userRoles && globalRoleDefinitions
    ? (userRoles
        .map((userRole) => {
          if (referenceId.includes(userRole.entityId!)) {
            const roleDefinition = globalRoleDefinitions.find(
              (role) => role.roleId === userRole.roleId
            );
            if (!roleDefinition) {
              return undefined;
            }
            return { entityId: userRole.entityId, ...roleDefinition };
          }
          return undefined;
        })
        .filter((e) => e) as AuthEntityRole[])
    : undefined;

export const getUniqueRoles = (
  refIds: string[],
  relevantUserRoles: AuthEntityRole[]
) =>
  refIds
    .map((id) => relevantUserRoles.filter((r) => r.entityId.includes(id)))
    .flat()
    .filter((v, i, a) => a.findIndex((t) => t.entityId === v.entityId) === i);

const hasStatementAction = (actions: AclAction[], action: string) =>
  !!actions.find((a) => a === action);

const hasPermissionLevel = (
  permissions: AuthMemberPermissions,
  level: AclLevel
) =>
  !!(
    permissions.auth &&
    (permissions.auth[level]?.allow || permissions.auth[level]?.deny)
  );

const addPermissionLevel = (
  permissions: AuthMemberPermissions,
  statement: AclStatement
) => {
  if (permissions.auth) {
    // eslint-disable-next-line no-param-reassign
    permissions.auth[statement.level] = {
      [statement.effect]: statement.actions,
    };
  }
};

const hasPermissionLevelAction = (
  permissions: AuthMemberPermissions,
  level: AclLevel,
  action: AclAction,
  effect: 'allow' | 'deny'
) =>
  !(
    !permissions.auth ||
    !permissions.auth[level]?.[effect]?.find((a) => a === action)
  );

const addPermissionLevelAction = (
  permissions: AuthMemberPermissions,
  statement: AclStatement,
  action: AclAction
) => {
  if (permissions?.auth) {
    const permissionActions =
      permissions.auth[statement.level]?.[statement.effect];
    if (permissionActions) {
      // eslint-disable-next-line no-param-reassign
      permissions.auth[statement.level]![statement.effect] = [
        ...permissionActions,
        action,
      ];
    } else {
      // eslint-disable-next-line no-param-reassign
      permissions.auth[statement.level] = {
        ...permissions.auth[statement.level],
        [statement.effect]: [action],
      };
    }
  }
};

const addPermissionLevelActions = (
  globalPermissionTree: Array<AclGlobalPermissionTreeNode>,
  permissions: AuthMemberPermissions,
  statement: AclStatement
) => {
  statement.actions?.forEach((action) => {
    if (action === AclAction.All) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      processPermissionNode(globalPermissionTree, statement, permissions);
    } else if (
      !hasPermissionLevelAction(
        permissions,
        statement.level,
        action,
        statement.effect
      )
    ) {
      addPermissionLevelAction(permissions, statement, action);
    }
  });
};

const addPermissions = (
  globalPermissionTree: Array<AclGlobalPermissionTreeNode>,
  permissions: AuthMemberPermissions,
  statement: AclStatement
) => {
  if (hasPermissionLevel(permissions, statement.level)) {
    addPermissionLevelActions(globalPermissionTree, permissions, statement);
  } else {
    addPermissionLevel(permissions, statement);
  }
};

const processWildcardInheritancePermissions = (
  globalPermissionTreeNode: Array<AclGlobalPermissionTreeNode>,
  statement: AclStatement,
  permissions: AuthMemberPermissions
) => {
  // Wildcard and inheritance, add node and child actions to permissions
  if (
    hasStatementAction(statement.actions, AclAction.All) &&
    statement.inheritance === true
  ) {
    const permissionTreeNode = searchPermissionTreeNode(
      globalPermissionTreeNode,
      statement.level
    );
    if (permissionTreeNode) {
      const flatTreeNodes = flatTree(permissionTreeNode);
      flatTreeNodes.forEach((node) => {
        addPermissions(globalPermissionTreeNode, permissions, {
          ...statement,
          level: node.level,
          actions: node.actions,
        });
      });
    }
  }
};

const processWildcardPermissions = (
  globalPermissionTree: Array<AclGlobalPermissionTreeNode>,
  statement: AclStatement,
  permissions: AuthMemberPermissions
) => {
  // Only wildcard, add node actions to permissions
  if (hasStatementAction(statement.actions, AclAction.All)) {
    const permissionTreeNode = searchPermissionTreeNode(
      globalPermissionTree,
      statement.level
    );
    if (permissionTreeNode) {
      addPermissions(globalPermissionTree, permissions, {
        ...statement,
        actions: permissionTreeNode.actions,
      });
    }
  }
};

const processExplicitPermissions = (
  statement: AclStatement,
  permissions: AuthMemberPermissions
) => {
  // Only explicit actions which will be added to permissions
  if (!hasStatementAction(statement.actions, AclAction.All)) {
    statement.actions.forEach((action) => {
      if (action !== AclAction.All) {
        if (
          !hasPermissionLevelAction(
            permissions,
            statement.level,
            action,
            statement.effect
          )
        ) {
          addPermissionLevelAction(permissions, statement, action);
        }
      }
    });
  }
};

export const processPermissionNode = (
  globalPermissionTree: AclGlobalPermissionTreeNode[],
  statement: AclStatement,
  permissions: AuthMemberPermissions
) => {
  processWildcardInheritancePermissions(
    globalPermissionTree,
    statement,
    permissions
  );
  processWildcardPermissions(globalPermissionTree, statement, permissions);
  processExplicitPermissions(statement, permissions);
};

export const getEntityPermissions = (
  referenceId: string,
  relevantUserRoles?: AuthEntityRole[],
  globalPermissionTree?: AclGlobalPermissionTreeNode[]
) => {
  if (relevantUserRoles && globalPermissionTree) {
    const permissions: AuthMemberPermissions = { auth: {} };

    // Split and map to ignore version parts of our uuid
    const refIds = getEntityIdsWithoutVersion(referenceId);

    // Build up clean referenceId
    let refId = refIds.join('#');

    // For bottom up permission calculation revert refId array
    const refIdsReverse = refIds.reverse();

    const uniqueRoles = getUniqueRoles(refIdsReverse, relevantUserRoles);
    refIdsReverse.forEach((e) => {
      const refIdRoles = uniqueRoles.filter((role) => role.entityId === refId);
      refIdRoles.forEach((role) => {
        role.statements.forEach((statement) => {
          if (statement) {
            processPermissionNode(globalPermissionTree, statement, permissions);
          }
        });
      });
      refId = refId.replace(`#${e}`, '');
    });
    return Object.keys(permissions).length === 0 ? undefined : permissions;
  }
  return undefined;
};
