import { RetryLink } from '@apollo/client/link/retry';
import { isMutation } from './utilities';
import { Execution } from '@/common/providers/AutoSavingProvider/AutoSavingContext';
import { getCurrentAutoSavingContext } from '@/common/providers/AutoSavingProvider/AutoSavingProvider';
import { AutoSavingError, AutoSavingStatus } from '@/types/autoSaving';

export const retryingLink = new RetryLink({
  delay: {
    initial: 10000,
    max: 30000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      // Set retry error messages for auto saving while retrying
      if (error && isMutation(operation)) {
        const operationContext = operation.getContext();
        const autoSavingContext = getCurrentAutoSavingContext();
        if (operationContext.id && autoSavingContext) {
          const execution: Execution = {
            id: operationContext.id,
            status: AutoSavingStatus.Retrying,
          };

          if (error.message === 'Failed to fetch') {
            autoSavingContext.updateExecution({
              ...execution,
              error: AutoSavingError.NetworkError,
            });
          } else {
            autoSavingContext.updateExecution({
              ...execution,
              error: AutoSavingError.Unexpected,
            });
          }
        }
      }

      return !!error;
    },
  },
});
