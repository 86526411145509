import { FieldFunctionOptions, FieldMergeFunction } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';
import { ArticleType } from '@/api/graphql';
import { StrictTypedTypePolicies } from '@/api/helpers';
import { getItemFromCache } from '@/api/hooks/cache/useGetItemFromCache';
import {
  TypeMapping,
  articleTableMapping,
  projectMetadataTypeMapping,
  userTypeMapping,
} from '@/api/mappings';
import { getEntityIdWithoutVersion } from '@/common/utils/getEntityIdWithoutVersion';

const getKeyArgsFunction = () => (args: Record<string, any> | null) =>
  args ? Object.keys(args).filter((a) => a !== 'nextToken') : [];

/** Concatenate the incoming list items with the existing list items. */
// eslint-disable-next-line @typescript-eslint/default-param-last
const mergeItems: FieldMergeFunction = (existing = { items: [] }, incoming) => {
  const items = existing.items
    ? uniqBy([...incoming.items, ...existing.items], '__ref')
    : undefined;
  return {
    ...existing,
    ...incoming,
    items,
  };
};

const mergeItemsResult = {
  keyArgs: getKeyArgsFunction(),
  merge: mergeItems,
};

/** Replaces  the existing list items with incoming list items. */
const replaceItems: FieldMergeFunction = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  existing = { items: [] },
  incoming
) => ({
  ...existing,
  ...incoming,
  items: incoming.items,
});

const replaceItemsResult = {
  keyArgs: getKeyArgsFunction(),
  merge: replaceItems,
};

const replaceArray: FieldMergeFunction = (_existing = [], incoming = []) => {
  return incoming;
};

const getFallback = (
  cachedValue: any,
  {
    readField,
    canRead,
    cache,
  }: FieldFunctionOptions<Record<string, any>, Record<string, any>>,
  referenceFieldName: string,
  mapping?: TypeMapping,
  transformReferenceFieldValue?: (value: string) => string
) => {
  if (mapping && (!canRead(cachedValue) || !cachedValue)) {
    let referenceFieldValue = readField(referenceFieldName);
    if (!referenceFieldValue) {
      return null;
    }

    if (transformReferenceFieldValue) {
      referenceFieldValue = transformReferenceFieldValue(
        `${referenceFieldValue}`
      );
    }

    const referenceValue = getItemFromCache(
      cache,
      `${referenceFieldValue}`,
      mapping,
      true
    );
    return referenceValue?.result ?? null;
  }

  // If there is a value, return it, because we don't need a fallback in that case
  return cachedValue;
};

export const typePolicies: StrictTypedTypePolicies = {
  ACLRoleAssignment: {
    fields: {
      data: {
        merge: replaceArray,
      },
    },
    merge: true,
  },
  ArticleMetadata: {
    fields: {
      articleGuidelines: {
        read: (cached, options) =>
          getFallback(
            cached,
            options,
            'id',
            articleTableMapping.typeMappings.get(ArticleType.Guidelines)
          ),
      },
      articleDocument: {
        read: (cached, options) =>
          getFallback(
            cached,
            options,
            'id',
            articleTableMapping.typeMappings.get(ArticleType.Document)
          ),
      },
      articleRScore: {
        read: (cached, options) =>
          getFallback(
            cached,
            options,
            'id',
            articleTableMapping.typeMappings.get(ArticleType.Rscore)
          ),
      },
      articleRootData: {
        read: (cached, options) =>
          getFallback(
            cached,
            options,
            'id',
            articleTableMapping.typeMappings.get(ArticleType.RootData),
            (id) => getEntityIdWithoutVersion(id)
          ),
      },
      project: {
        read: (cached, options) =>
          getFallback(cached, options, 'projectId', projectMetadataTypeMapping),
      },
      assignee: {
        read: (cached, options) =>
          getFallback(cached, options, 'assigneeId', userTypeMapping),
      },
      versionCreator: {
        read: (cached, options) =>
          getFallback(cached, options, 'versionCreatorId', userTypeMapping),
      },
      competitorsUrls: {
        merge: replaceArray,
      },
      readingResources: {
        merge: replaceArray,
      },
    },
    merge: true,
  },
  ArticleGuidelines: {
    merge: true,
  },
  ArticleDocument: {
    merge: true,
  },
  ArticleRScore: {
    merge: true,
  },
  ArticleComment: {
    merge: true,
    fields: {
      commentCreator: {
        read: (cached, options) => {
          return getFallback(cached, options, 'createdBy', userTypeMapping);
        },
      },
    },
  },
  ArticlePost: {
    merge: true,
    fields: {
      advices: {
        read: (cached, options) => {
          return getFallback(
            cached,
            options,
            'id',
            articleTableMapping.typeMappings.get(ArticleType.PostAdvices)
          );
        },
      },
    },
  },
  ArticlePostAdvices: {
    merge: true,
  },
  ProjectBrief: {
    merge: true,
  },
  ProjectBriefDocument: {
    merge: true,
  },
  ProjectBriefMetadata: {
    fields: {
      inputTopics: {
        merge: replaceArray,
      },
    },
    merge: true,
  },
  ProjectMetadata: {
    fields: {
      topicInspirations: {
        merge: replaceArray,
      },
      brief: {
        merge: (existing, incoming, { mergeObjects }) => {
          if (!incoming) {
            return existing;
          }
          return mergeObjects(existing, incoming);
        },
      },
    },
    merge: true,
  },
  User: {
    merge: true,
  },
  ArticleKeyword: {
    merge: true,
  },
  ArticleQuestion: {
    merge: true,
  },
  ArticleHeadline: {
    merge: true,
    fields: {
      keywords: {
        merge: replaceArray,
      },
      questions: {
        merge: replaceArray,
      },
    },
  },
  Invitation: {
    merge: true,
  },
  Notification: {
    merge: true,
  },
  Organization: {
    merge: true,
  },
  ProjectMember: {
    merge: true,
  },
  NVeCompany: {
    merge: true,
  },
  NVeTopic: {
    merge: true,
  },
  NVeTopicArticle: {
    merge: true,
  },
  ContentCategory: {
    fields: {
      subCategories: {
        merge(existing = [], incoming = []) {
          return [...existing, ...incoming];
        },
      },
    },
  },
  Query: {
    fields: {
      listArticles: mergeItemsResult,
      listNotifications: mergeItemsResult,
      listProjects: mergeItemsResult,
      listArticleHeadlines: replaceItemsResult,
      listArticleComments: mergeItemsResult,
      listGSCArticlesByProject: mergeItemsResult,
    },
  },
};
