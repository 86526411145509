import { ApolloLink, ApolloCache, NextLink, Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { DocumentNode } from 'graphql';
import isEqual from 'lodash/isEqual';
import { isSubscription } from './utilities';
import { TableMapping, mappings } from '@/api/mappings';
import { handleSubscriptionMessage, getCacheId } from '@/api/utils';
import { isWithTypename } from '@/common/utils/typeGuards';

const findTableMappingForOperation = (
  query: DocumentNode,
  operationKind: Extract<
    keyof TableMapping,
    'createSubscriptions' | 'updateSubscriptions' | 'deleteSubscriptions'
  >
) => {
  const mainDefinition = getMainDefinition(query);
  for (let tableMapping of mappings) {
    if (
      tableMapping[operationKind].some((subscription) =>
        isEqual(getMainDefinition(subscription).name, mainDefinition.name)
      )
    ) {
      return tableMapping;
    }
  }

  return null;
};

const handleCreateOrUpdateSubscriptionResponse = (
  operation: Operation,
  forward: NextLink,
  cache: ApolloCache<any>,
  mapping: TableMapping
): ReturnType<NextLink> =>
  forward(operation).map((result) => {
    handleSubscriptionMessage(result.data, (item) => {
      const typeMapping = mapping.typeMappings.get(
        item.type ?? item.__typename ?? ''
      );
      if (typeMapping) {
        // Replace the typename, because we got the one for the database table (e.g. ArticleBriefSubscription)
        if (isWithTypename(item)) {
          item.__typename = typeMapping.typename;
        }

        /*
          Update the item in the cache or insert it, because the way via the ApolloLink only inserts the item without
          its data (only its id and typename)
        */
        cache.writeFragment({
          fragment: typeMapping.fragment,
          fragmentName: typeMapping.fragmentName,
          id: getCacheId(item, typeMapping),
          data: {
            ...typeMapping.defaults,
            ...item,
          },
          broadcast: true,
        });
      }
    });
    return result;
  });

const handleDeleteSubscriptionResponse = (
  operation: Operation,
  forward: NextLink,
  cache: ApolloCache<any>,
  mapping: TableMapping
): ReturnType<NextLink> =>
  forward(operation).map((result) => {
    handleSubscriptionMessage(result.data, (item) => {
      const typeMapping = mapping.typeMappings.get(
        item.type ?? item.__typename ?? ''
      );
      if (typeMapping) {
        const id = getCacheId(item, typeMapping);
        const cacheId = cache.identify({
          __ref: id,
        });
        if (cacheId) {
          // Remove the found item from the apollo cache
          cache.evict({ id: cacheId });
          cache.gc();
        }
      }
    });
    return result;
  });

export const createSubscriptionResponseLink = (cache: ApolloCache<any>) =>
  new ApolloLink((operation, forward) => {
    if (isSubscription(operation)) {
      let tableMapping: TableMapping | null;
      // Create or Update subscription
      if (
        (tableMapping = findTableMappingForOperation(
          operation.query,
          'updateSubscriptions'
        )) ||
        (tableMapping = findTableMappingForOperation(
          operation.query,
          'createSubscriptions'
        ))
      ) {
        return handleCreateOrUpdateSubscriptionResponse(
          operation,
          forward,
          cache,
          tableMapping
        );
      }

      // Delete subscription
      if (
        (tableMapping = findTableMappingForOperation(
          operation.query,
          'deleteSubscriptions'
        ))
      ) {
        return handleDeleteSubscriptionResponse(
          operation,
          forward,
          cache,
          tableMapping
        );
      }
    }

    return forward(operation);
  });
