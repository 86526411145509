import { useContext } from 'react';
import {
  useGetAuth0RoleLazyQuery,
  useGetUser,
  useOnUserUpdate,
} from '@/api/graphql';
import { useCheckSession } from '@/common/hooks/useCheckSession';
import { AuthenticationContext } from '@/common/providers/AuthenticationProvider/AuthenticationContext';

export const useUserSubscription = () => {
  const checkSession = useCheckSession();
  const { data, refetch, ...rest } = useGetUser();
  const context = useContext(AuthenticationContext);
  const { organizationId, userId } = context.claims!;
  const [getAuth0Role] = useGetAuth0RoleLazyQuery();

  useOnUserUpdate({
    variables: {
      organizationId,
      userId,
    },
    skip: !userId || !organizationId,
    onData: async () => {
      const currentAuth0Role = data?.getUser?.userOrgData?.auth0?.role;
      const res = await getAuth0Role();
      const newAuth0Role = res.data?.getUser?.userOrgData?.auth0?.role;

      if (newAuth0Role && currentAuth0Role !== newAuth0Role) {
        // Check the session and rerun all requests with the new token
        await checkSession();
      } else {
        // Refetch only the user's data
        await refetch();
      }
    },
  });

  return {
    user: data?.getUser,
    data,
    refetch,
    ...rest,
  };
};
