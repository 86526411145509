import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client';
import { AuthLink } from 'aws-appsync-auth-link/lib/auth-link';
import { isSameDay } from 'date-fns';
import { AnalyticsLogType, LogAnalyticsDocument } from '@/api/graphql';

let lastUserAction: Date;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

/**
 * Link that requests the analytics endpoint to track daily active users
 */
export const createAnalyticsLink = (
  authLink: AuthLink,
  subscriptionLink: ApolloLink
) =>
  new ApolloLink((operation, forward) => {
    try {
      const currentDate = new Date();
      if (!lastUserAction || !isSameDay(lastUserAction, currentDate)) {
        lastUserAction = currentDate;
        const client = new ApolloClient({
          link: ApolloLink.from([authLink, subscriptionLink]),
          cache: new InMemoryCache(),
          defaultOptions,
          name: 'Analytics',
        });
        client
          .mutate({
            mutation: LogAnalyticsDocument,
            variables: {
              input: {
                logType: AnalyticsLogType.DailyActiveUser,
              },
            },
          })
          .then(() => {})
          .catch((e) => console.log(e));
      }
    } catch (error) {
      console.log('createAnalyticsLink', error);
    }
    return forward(operation);
  });
