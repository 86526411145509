'use client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseErrorPage } from './BaseErrorPage';
import { SadRelliIcon } from '@/common/icons';

export const UnexpectedErrorPage = () => {
  const { t } = useTranslation();

  const [toHref, setToHref] = useState('');
  useEffect(() => {
    setToHref(window.origin);
  }, []);

  return (
    <BaseErrorPage
      title={t('global:errors:unexpected:title')}
      description={
        <>
          {t('global:errors:unexpected:workingOnFixingTheIssue')}
          <br />
          {t('global:errors:unexpected:tryAgainLater')}
        </>
      }
      icon={<SadRelliIcon width="52px" height="52px" />}
      toHref={toHref}
      toText={t('global:errors:unexpected:button')}
    />
  );
};
