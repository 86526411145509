import { ApolloLink, FetchResult, Operation } from '@apollo/client';
import { isField } from '@apollo/client/utilities';
import {
  DefinitionNode,
  FieldNode,
  isExecutableDefinitionNode,
  OperationDefinitionNode,
  SelectionNode,
} from 'graphql';

/**
 * By default only subscriptions caused by update or upsert mutations are skipped (updateToken passed)
 * The list contains mutations that should get an updateToken too.
 */
const customMutationOperationNames: string[] = [
  'createArticleHeadlineRefs',
  'generateOutlineByGPT',
  'deleteSavedNeuraverseTopic',
  'createSavedNeuraverseTopic',
];

/** Get the mutation operation if available */
export const isOperationDefinitionNode = (
  def: DefinitionNode
): def is OperationDefinitionNode =>
  isExecutableDefinitionNode(def) &&
  !!(def as OperationDefinitionNode)?.operation;

/** Get the mutation operation if available */
export const getMutationOperation = (operation: Operation) =>
  operation.query.definitions.find(
    (def): def is OperationDefinitionNode =>
      isOperationDefinitionNode(def) && def.operation === 'mutation'
  );

/** Get the subscription operation if available */
export const getSubscriptionOperation = (operation: Operation) =>
  operation.query.definitions.find(
    (def): def is OperationDefinitionNode =>
      isOperationDefinitionNode(def) && def.operation === 'subscription'
  );

/** Return the operation name i.e. onTopicUpdate */
export const getOperationName = (operation?: OperationDefinitionNode) =>
  operation?.selectionSet.selections.find((selection): selection is FieldNode =>
    isField(selection)
  )?.name.value;

/** Return the update mutation operation name i.e. updateTopic */
export const getUpdateOperationName = (operation: Operation) => {
  const operationName = getOperationName(getMutationOperation(operation));
  return operationName &&
    (operationName.startsWith('update') ||
      operationName.startsWith('upsert') ||
      customMutationOperationNames.includes(operationName))
    ? operationName
    : undefined;
};

/**
 * Inject the client id into the input if it's type returns an updateToken
 */
const setUpdateToken = (
  clientToken: string,
  field: SelectionNode,
  operation: Operation
) => {
  if (isField(field)) {
    if (field.name.value === 'updateToken') {
      // eslint-disable-next-line no-param-reassign
      operation.variables.input.updateToken = clientToken;
      // console.log('Set updateToken for: ', getUpdateOperationName(operation)); // Debug
    }
  }
};

/** Returns false for subscription responses who's updateToken matches the clientId */
const isClientCausedUpdateSubscription = (
  clientToken: string,
  res: FetchResult<
    Record<string, any>,
    Record<string, any>,
    Record<string, any>
  >,
  subscriptionOperationName?: string
) => {
  const result =
    subscriptionOperationName && res.data?.[subscriptionOperationName];
  if (result?.updateToken === clientToken) {
    // console.log('Prevent: ', subscriptionOperationName); // Debug
    return false;
  }
  return true;
};

/**
 * This middleware attaches an updateToken to every request that supports it and cancels every subscription that has the same token.
 */
export const createSubscriptionFilterLink = (clientToken: string) =>
  new ApolloLink((operation, forward) => {
    const updateMutationName = getUpdateOperationName(operation);
    const isUpdateOperation = !!updateMutationName;
    operation.query.definitions.forEach((definition) => {
      if (isExecutableDefinitionNode(definition) && isUpdateOperation) {
        definition.selectionSet.selections.forEach((selection) => {
          if (isField(selection)) {
            if (selection.selectionSet) {
              selection.selectionSet.selections.forEach((field) => {
                setUpdateToken(clientToken, field, operation);
              });
            } else {
              setUpdateToken(clientToken, selection, operation);
            }
          }
        });
      }
    });

    const subscriptionOperationName = getOperationName(
      getSubscriptionOperation(operation)
    );

    return forward(operation).filter((result) =>
      // Filter out subscriptions caused by this client
      isClientCausedUpdateSubscription(
        clientToken,
        result,
        subscriptionOperationName
      )
    );
  });
