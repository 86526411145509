import LogRocket from 'logrocket';

export const getSessionUrl = (loggingEnabled: boolean) =>
  new Promise<string>((resolve) => {
    if (loggingEnabled) {
      LogRocket.getSessionURL(resolve);
    } else {
      resolve('');
    }
  });
