'use client';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseErrorPage } from './BaseErrorPage';
import { SadRelliIcon } from '@/common/icons';

export const Error404Page: FC = () => {
  const { t } = useTranslation();

  return (
    <BaseErrorPage
      title={t('global:errors:404:title')}
      icon={<SadRelliIcon width="52px" height="52px" />}
      toHref="/"
      toText={t('global:errors:404:button')}
    />
  );
};
