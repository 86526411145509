import { createContext } from 'react';
import { AclAction, UserRoleInput, AclLevel } from '@/api/graphql';

type AuthorizationContextType = {
  getPermissions: (referenceId?: string) => {
    [key: string]: AclAction[] | undefined;
  };
  grantUserRoles: (input: UserRoleInput) => void;
  revokeUserRoles: (input: UserRoleInput) => void;
  isAuthorized: (
    level: AclLevel,
    action: AclAction,
    referenceId?: string
  ) => boolean;
  /** Evaluate permissions for a list of actions for the same level and referenceId */
  getAuthorizedActions: <T extends AclAction>(
    level: AclLevel,
    actions: T[],
    referenceId?: string
  ) => Map<T, boolean>;
  isAdmin: boolean;
  roleAssignments:
    | {
        roleId?: string | null | undefined;
        entityId?: string | null | undefined;
      }[]
    | undefined;
};

const initialValue: AuthorizationContextType = {
  grantUserRoles: async () => {},
  revokeUserRoles: async () => {},
  getPermissions: () => ({}),
  isAuthorized: () => false,
  getAuthorizedActions: () => new Map(),
  isAdmin: false,
  roleAssignments: [],
};

export const AuthorizationContext =
  createContext<AuthorizationContextType>(initialValue);
