'use client';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserSubscription } from '@/common/hooks/graphql/useUserSubscription';

export const LanguageSelector: FC = () => {
  const { i18n } = useTranslation('navigation');

  const { user } = useUserSubscription();
  useEffect(() => {
    if (user?.locale) {
      i18n.changeLanguage(user.locale);
    }
  }, [i18n, user?.locale]);

  return <></>;
};
