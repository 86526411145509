import { ApolloLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import LogRocket from 'logrocket';
import { isSubscription } from './utilities';
import { getSessionUrl } from '@/common/utils/getSessionUrl';

const createLoggingSubscriptionMessagesLink = (loggingEnabled: boolean) =>
  new ApolloLink((operation, forward) => {
    if (isSubscription(operation)) {
      return forward(operation).map((result) => {
        // Log subscript messages, because these aren't tracked by logrocket per default
        if (loggingEnabled && result) {
          LogRocket.debug('SubscriptionMessage', result);
        }

        return result;
      });
    }

    return forward(operation);
  });

const createContextLink = (clientToken: string, loggingEnabled: boolean) =>
  setContext(async (operation, prevContext) => {
    // Log initializing a subscription, because these aren't tracked by logrocket per default
    if (loggingEnabled && isSubscription(operation)) {
      LogRocket.debug('SubscriptionInit', operation);
    }

    // Get the session url
    const sessionUrl = await getSessionUrl(loggingEnabled);

    // Attach the logging session and the client token as additional headers for the BE
    return {
      ...prevContext,
      headers: {
        ...prevContext.headers,
        'x-client-token': clientToken,
        'x-logging-session': sessionUrl,
      },
    };
  });

export const createLoggingLink = (
  clientToken: string,
  loggingEnabled: boolean
) => {
  const contextLink = createContextLink(clientToken, loggingEnabled);
  const loggingSubscriptionMessagesLink =
    createLoggingSubscriptionMessagesLink(loggingEnabled);
  return from([contextLink, loggingSubscriptionMessagesLink]);
};
