'use client';
import React, { FC, PropsWithChildren, useContext } from 'react';
import { Error403Page } from './Error403Page';
import { Error404Page } from './Error404Page';
import { UnexpectedErrorPage } from './UnexpectedErrorPage';
import { AuthenticationContext } from '@/common/providers/AuthenticationProvider/AuthenticationContext';

const hasTo = (input: any): input is { to: string } =>
  !!input && 'to' in input && typeof input.to === 'string';

type ErrorHandlerProps = {
  auth0OrgId?: string;
} & PropsWithChildren;

type ErrorHandlerState = {
  error?: Error;
  auth0OrgId?: string;
};

class ErrorHandler extends React.Component<
  ErrorHandlerProps,
  ErrorHandlerState
> {
  constructor(props: ErrorHandlerProps) {
    super(props);

    this.state = {
      auth0OrgId: props.auth0OrgId,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error) {
    if (error.message !== '403' && error.message !== '404') {
      console.error(error);
    }

    if (error.message === '403' && hasTo(error.cause)) {
      window.location.href = error.cause.to;
    }
  }

  render(): JSX.Element {
    const { error, auth0OrgId } = this.state;
    if (error) {
      if (error.message === '403') {
        if (hasTo(error.cause)) {
          return <></>;
        }

        if (auth0OrgId) {
          return <Error403Page />;
        }
      }

      if (error.message === '404' && auth0OrgId) {
        return <Error404Page />;
      }

      return <UnexpectedErrorPage />;
    }

    // Return children if there is no error
    return <>{this.props.children}</>;
  }
}

export const ErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const { auth0OrgId } = useContext(AuthenticationContext);
  return <ErrorHandler auth0OrgId={auth0OrgId}>{children}</ErrorHandler>;
};
