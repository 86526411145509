import { useCallback, useRef } from 'react';
import { v4 as uuid } from 'uuid';

type Subscriber<T> = (item: T) => void;

const defaultTopic = 'NONE';

export const usePubSub = <T = any>() => {
  const subscribers = useRef<Map<string, Subscriber<T>>>(
    new Map<string, Subscriber<T>>()
  );

  const publish = useCallback((item: T, topic: string = defaultTopic) => {
    subscribers.current.forEach((subscriber, key) => {
      if (key.startsWith(`${topic}#`)) {
        subscriber(item);
      }
    });
  }, []);

  const subscribe = useCallback(
    (subscriber: Subscriber<T>, topic: string = defaultTopic) => {
      const subscriptionId = `${topic}#${uuid()}`;
      subscribers.current.set(subscriptionId, subscriber);
      return subscriptionId;
    },
    []
  );

  const unsubscribe = useCallback(
    (subscriptionId: string) => subscribers.current.delete(subscriptionId),
    []
  );

  return {
    publish,
    subscribe,
    unsubscribe,
  };
};
