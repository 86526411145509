import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { createAnalyticsLink } from './analyticsLink';
import { autoSavingLink } from './autoSavingLink';
import { errorLink } from './errorLink';
import { fetchAllLink } from './fetchAllLink';
import { createLoggingLink } from './loggingLink';
import { retryingLink } from './retryingLink';
import { createSubscriptionFilterLink } from './subscriptionFilterLink';
import { createSubscriptionResponseLink } from './subscriptionResponseLink';
import { typenameLink } from './typenameLink';
import { typePolicies } from './typePolicies';
import { defaultWatchQueryOptions } from '@/constants';

type DeletedObject = {
  id: string;
  __typename: string;
};
type DeleteResponse = {
  data?: {
    [mutation: string]: DeletedObject | DeletedObject[];
  };
};

export const createApolloClient = (
  url: string,
  clientToken: string,
  getToken: () => Promise<string>,
  loggingEnabled: boolean
) => {
  const options: {
    url: string;
    region: string;
    auth: AuthOptions;
  } = {
    auth: {
      type: 'OPENID_CONNECT',
      jwtToken: getToken,
    },
    region: process.env.NEXT_PUBLIC_APPSYNC_GQL_REGION,
    url,
  };

  const authLink = createAuthLink(options);
  const subscriptionLink = createSubscriptionHandshakeLink(options);
  const loggingLink = createLoggingLink(clientToken, loggingEnabled);

  const cache = new InMemoryCache({
    resultCaching: true,
    typePolicies,
  });

  const subscriptionResponseLink = createSubscriptionResponseLink(cache);
  const subscriptionFilterLink = createSubscriptionFilterLink(clientToken);
  const analyticsLink = createAnalyticsLink(authLink, subscriptionLink);
  return new ApolloClient({
    link: from([
      fetchAllLink,
      typenameLink,
      autoSavingLink,
      errorLink,
      retryingLink,
      subscriptionResponseLink,
      subscriptionFilterLink,
      loggingLink,
      analyticsLink,
      authLink,
      subscriptionLink,
    ]),

    cache,
    connectToDevTools: process.env.NEXT_PUBLIC_NODE_ENV === 'development',
    assumeImmutableResults: true,
    // the defaultOptions aren't working, so we have to add these to the base options in graphql.ts
    defaultOptions: {
      watchQuery: {
        ...defaultWatchQueryOptions,
      },
      mutate: {
        update: (currentCache, result) => {
          const response = result as DeleteResponse;
          if (response.data) {
            Object.entries(response.data).forEach(([mutation, res]) => {
              const blacklist: string[] = [
                'deleteArticleHeadlineRef',
                'deleteApiConnection',
              ];
              if (
                mutation.startsWith('delete') &&
                !blacklist.includes(mutation)
              ) {
                // Bulk operations return an array of deleted items
                const items = Array.isArray(res) ? res : [res];
                for (const item of items) {
                  const cacheId = currentCache.identify({
                    id: item.id,
                    // eslint-disable-next-line no-underscore-dangle
                    __typename: item.__typename,
                  });
                  currentCache.evict({ id: cacheId });
                  currentCache.gc();
                }
              }
            });
          }
        },
      },
    },
  });
};
