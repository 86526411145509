import { useCallback } from 'react';
import { ApolloCache, useApolloClient } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import { TypeMapping } from '@/api/mappings';

export const getItemFromCache = <T>(
  cache: ApolloCache<object>,
  id: string,
  mapping?: TypeMapping,
  includeOptimistic: boolean = true
) => {
  if (mapping) {
    const cacheId = cache.identify({
      id,
      __typename: mapping.typename,
    });
    if (cacheId) {
      const diff = cache.diff<T>({
        id: cacheId,
        // eslint-disable-next-line @typescript-eslint/dot-notation
        query: cache['getFragmentDoc'](mapping.fragment, mapping.fragmentName),
        // We also want to include optimistic data from optimistic responses by default
        optimistic: includeOptimistic,
        // E.g. if we request a fragment with full data, not all properties could be in the cache. So we want at least partial data
        returnPartialData: true,
      });

      // Return null if the result is empty (not found)
      if (diff.result && isEqual(diff.result, {})) {
        return null;
      }

      return diff;
    }
  }

  return null;
};

export const useGetItemFromCache = () => {
  const { cache } = useApolloClient();

  const getFromCache = useCallback(
    <T>(id: string, mapping?: TypeMapping, includeOptimistic: boolean = true) =>
      getItemFromCache<T>(cache, id, mapping, includeOptimistic),
    [cache]
  );

  return getFromCache;
};
