'use client';
import React, {
  FC,
  useCallback,
  useEffect,
  useContext,
  useMemo,
  PropsWithChildren,
} from 'react';
import { AuthorizationContext } from './AuthorizationContext';
import { getEntityPermissions, getRelevantUserRoles } from './utils';
import {
  useGetGlobalPermissionTree,
  useGetGlobalRoleDefinitions,
  useGetUserRoleAssignments,
  OnUserRoleAssignments,
  OnUserRoleAssignmentsVariables,
  OnUserRoleAssignmentsDocument,
  AclGlobalPermissionTreeNode,
  AclAction,
  Auth0Role,
  AclLevel,
  useGrantUserRoles,
  UserRoleInput,
  useRevokeUserRoles,
} from '@/api/graphql';
import { AuthenticationContext } from '@/common/providers/AuthenticationProvider/AuthenticationContext';
import { Spinner } from '@/common/Spinner';

export const AuthorizationProvider: FC<PropsWithChildren> = ({ children }) => {
  const context = useContext(AuthenticationContext);
  const { organizationId, userId, auth0Role } = context.claims!;

  const {
    data: globalPermissionTreeData,
    loading: globalPermissionsQueryLoading,
  } = useGetGlobalPermissionTree();
  const globalPermissionTree =
    globalPermissionTreeData?.getGlobalPermissionsTree?.data;

  const {
    data: globalDefinitionsData,
    loading: globalDefinitionsQueryLoading,
  } = useGetGlobalRoleDefinitions();
  const globalRoleDefinitions =
    globalDefinitionsData?.getGlobalRoleDefinitions?.data;

  const {
    data: userRoleAssignmentsData,
    subscribeToMore,
    loading: userRoleDataLoading,
  } = useGetUserRoleAssignments({
    variables: {
      id: userId as never,
    },
    skip: !userId,
  });

  const roleAssignments = userRoleAssignmentsData?.getUserRoleAssignments?.data;

  // Update the user role assignments, when these were changed
  useEffect(() => {
    if (userId) {
      subscribeToMore<OnUserRoleAssignments, OnUserRoleAssignmentsVariables>({
        document: OnUserRoleAssignmentsDocument,
        variables: {
          id: userId,
        },
        updateQuery: (prevQueryData, { subscriptionData }) => ({
          __typename: 'Query',
          getUserRoleAssignments: {
            ...prevQueryData.getUserRoleAssignments,
            id: prevQueryData.getUserRoleAssignments?.id ?? '',
            data: subscriptionData?.data?.onUserRoleAssignments?.data ?? [],
          },
        }),
      });
    }
  }, [userId, subscribeToMore]);

  //  Parse permission tree and role assignments
  const getPermissions = useCallback(
    (referenceId?: string) => {
      let refId = referenceId;
      if (!refId) {
        // use org id if no refId is defined
        if (organizationId) {
          refId = organizationId;
        } else {
          return {};
        }
      }

      const relevantUserRoles = getRelevantUserRoles(
        refId,
        roleAssignments,
        globalRoleDefinitions
      );
      const entityPermissions = getEntityPermissions(
        refId,
        relevantUserRoles,
        globalPermissionTree as AclGlobalPermissionTreeNode[]
      );

      return entityPermissions?.auth
        ? Object.entries(entityPermissions.auth).reduce<{
            [key: string]: AclAction[] | undefined;
          }>((acc, [key, val]) => {
            // filter any explicit deny action from allow
            acc[key] =
              val?.allow?.filter((action) => !val.deny?.includes(action)) ?? [];
            return acc;
          }, {})
        : {};
    },
    [
      organizationId,
      roleAssignments,
      globalRoleDefinitions,
      globalPermissionTree,
    ]
  );

  const isAdmin = auth0Role === Auth0Role.Admin;

  const isAuthorized = useCallback(
    (level: AclLevel, action: AclAction, referenceId?: string) => {
      if (isAdmin) {
        return true;
      }
      const permissions = getPermissions(referenceId);
      return !!(
        permissions?.[level]?.includes(action) ??
        permissions?.[level]?.includes(AclAction.All)
      );
    },
    [getPermissions, isAdmin]
  );

  const getAuthorizedActions = useCallback(
    <T extends AclAction>(
      level: AclLevel,
      actions: T[],
      referenceId?: string
    ) => {
      if (isAdmin) {
        return new Map(actions.map((action) => [action, true]));
      }
      const permissions = getPermissions(referenceId);
      return new Map(
        actions.map((action) => [
          action,
          !!(
            permissions?.[level]?.includes(action) ??
            permissions?.[level]?.includes(AclAction.All)
          ),
        ])
      );
    },
    [getPermissions, isAdmin]
  );

  // Grant a user a specific role
  const [grantUserRolesMutation] = useGrantUserRoles();
  const grantUserRoles = useCallback(
    async (input: UserRoleInput) => {
      const res = await grantUserRolesMutation({
        variables: {
          input,
        },
      });
      return res.data?.grantUserRoles;
    },
    [grantUserRolesMutation]
  );

  // Revoke a specific role from a user
  const [revokeUserRolesMutation] = useRevokeUserRoles();
  const revokeUserRoles = useCallback(
    async (input: UserRoleInput) => {
      const res = await revokeUserRolesMutation({
        variables: {
          input,
        },
      });
      return res.data?.revokeUserRoles;
    },
    [revokeUserRolesMutation]
  );

  const arePermissionsDataLoading = useMemo(
    () =>
      globalPermissionsQueryLoading ||
      globalDefinitionsQueryLoading ||
      userRoleDataLoading,
    [
      globalDefinitionsQueryLoading,
      globalPermissionsQueryLoading,
      userRoleDataLoading,
    ]
  );

  return (
    <AuthorizationContext.Provider
      value={{
        grantUserRoles,
        revokeUserRoles,
        getPermissions,
        isAuthorized,
        getAuthorizedActions,
        isAdmin,
        roleAssignments,
      }}
    >
      {arePermissionsDataLoading ? <Spinner /> : children}
    </AuthorizationContext.Provider>
  );
};
