'use client';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  PropsWithChildren,
} from 'react';
import { ApolloError, ApolloProvider } from '@apollo/client';
import LogRocket from 'logrocket';
import { v4 as uuid } from 'uuid';
import { createApolloClient } from './apolloClient';
import { GraphqlContext, OptimisticEvent } from './GraphqlContext';
import { usePubSub } from '@/common/hooks/usePubSub';
import { AuthenticationContext } from '@/common/providers/AuthenticationProvider/AuthenticationContext';

export const GraphqlProvider: FC<PropsWithChildren> = ({ children }) => {
  const { client: authClient, loggingEnabled } = useContext(
    AuthenticationContext
  );

  const { current: clientToken } = useRef(uuid());

  useEffect(() => {
    if (loggingEnabled) {
      // Track session specific information
      LogRocket.track('clientToken', {
        clientToken,
      });
    }
  }, [clientToken, loggingEnabled]);

  const client = useMemo(() => {
    // For dev/qa/prod we get the appsync endpoint url dynamically
    const url = process.env.NEXT_PUBLIC_APPSYNC_GQL_ENDPOINT;

    const apolloClient = createApolloClient(
      url,
      clientToken,
      async () => {
        const accessToken = await authClient?.getTokenSilently();
        return accessToken ?? '';
      },
      loggingEnabled
    );
    return apolloClient;
  }, [authClient, clientToken, loggingEnabled]);

  // Hide debouncing errors from the console. These will be shown as warnings during development
  const hideDebounceErrors = useCallback((event: PromiseRejectionEvent) => {
    if (
      event.reason instanceof ApolloError &&
      event.reason.message === 'debounced'
    ) {
      if (!!process.env.NEXT_PUBLIC_DEBUG) {
        console.warn(event.reason);
      }

      event.preventDefault();
    }
  }, []);
  useEffect(() => {
    window.addEventListener('unhandledrejection', hideDebounceErrors);
    return () => {
      window.removeEventListener('unhandledrejection', hideDebounceErrors);
    };
  }, [hideDebounceErrors]);

  const {
    publish: publishCreate,
    subscribe: subscribeCreate,
    unsubscribe: unsubscribeCreate,
  } = usePubSub<OptimisticEvent>();

  return (
    <GraphqlContext.Provider
      value={{
        clientToken,
        publishCreate,
        subscribeCreate,
        unsubscribeCreate,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </GraphqlContext.Provider>
  );
};
